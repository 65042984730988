<template>
    <header class="bg-secondary w-full fixed top-0 left-0">
        <nav
            class="mx-auto flex flex-row max-w-7xl items-center justify-between p-5 lg:px-8"
            aria-label="Global"
        >
            <div :class="isHome ? 'hidden' : 'flex flex-1'">
                <a
                    :href="sanitizeUriWithParams('/')"
                    class="-m-1.5 w-12 h-12 text-white bg-transparent hover:bg-gray-700 p-2 rounded-full flex items-center justify-center"
                >
                    <ArrowLeftIcon class="w-10 h-10" key="chevron-icon" />
                </a>
            </div>
            <div
                :class="
                    isHome
                        ? 'flex flex-1 justify-start'
                        : 'flex flex-1 justify-center'
                "
            >
                <a :href="sanitizeUriWithParams('/')" class="-m-1.5 p-1">
                    <span class="sr-only">Courserev</span>
                    <img
                        class="w-12 h-12 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                        :src="logoImage || defaultAvatar"
                        alt="Bordered avatar"
                    />
                </a>
            </div>
            <div class="flex flex-1"></div>
        </nav>

        <div
            v-if="env === 'dev'"
            class="w-full p-2 m-0 bg-red-500 flex justify-center items-center text-white font-bold text-sm"
        >
            <span class="text-center">Currently in dev environment</span>
        </div>
    </header>
</template>

<script setup lang="ts">
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";
import { useCourse } from "@/composables/course";
import { useGeneral } from "@/composables/general";
const { logoImage }: any = useCourse();
const config = useRuntimeConfig();
const { sanitizeUriWithParams } = useGeneral();
const env = config.public?.env || "dev";
const route = useRoute();
const isHome = computed(() => route.path === "/");
const defaultAvatar = "/images/courserev_ai.png";
</script>
